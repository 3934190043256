'use client'

import React from 'react'
import { AuthModalTriggers } from '../../../../features/user/auth-modals/auth-modal-triggers'
import { useUser } from '../../../../features/user/user.store'
import styles from './page-header.module.css'
import clsx from 'clsx'

export function PageHeaderAuthTriggers() {
  const user = useUser()

  return !user?.id ? (
    <>
      <AuthModalTriggers
        delay={0}
        wrapperLogInClassName={styles.pageHeaderLoginLink}
        wrapperSignUpClassName={styles.pageHeaderSignupLink}
        wrapperClassName={clsx(
          styles.pageHeaderAuthLinksWrapper,
          styles.pageHeaderPipe
        )}
        logInLinkClassName={styles.pageHeaderLink}
        signUpLinkClassName={styles.pageHeaderLink}
        logInDataTid="nav-bar-log-in"
        signUpDataTid="nav-bar-sign-up"
        hideSignUp={false}
      />
    </>
  ) : null
}
