import { match } from 'path-to-regexp'
import { semLanders } from './sem-landers'
import { seoLanders } from './seo-landers'
import { nearMe } from './near-me'

export const genericRewrites: Record<string, string> = {
  ...seoLanders,
  ...nearMe,

  // 2024 mask consolidation - SEM routes
  ...semLanders,

  // this one 301 redirects on purpose
  '/search-lander': `/search?isGenericRewrite=1&propertyTypes=apartments`,
}

export const genericRewritesPaths = Object.keys(genericRewrites)

export const roomsForRentMatcher = match<{ state: string; location: string }>(
  '/:state/:location-rooms-for-rent'
)
