import type { GraphqlRequesterOptions } from '@rentpath/graphql-requester'
import { getEnv } from '../../../config/get-env'

const env = getEnv()

const url =
  typeof document !== 'undefined'
    ? '/graphql'
    : `${process.env.RENT_API_PROTOCOL || 'http'}://${
        process.env.RENT_API_HOST
      }/graphql`

export const graphqlRequesterOptions: GraphqlRequesterOptions = {
  url,
  brand: 'rent',
  clientName: env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT
    ? `rent-web-${env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT}`
    : 'rent-web-unknown-env',
  clientVersion: env.NEXT_PUBLIC_VERSION,
}
