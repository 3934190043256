'use client'

import clsx from 'clsx'
import type { ReactNode } from 'react'
import styles from './nav-links.module.css'
import sharedStyles from '../../../../features/page-header/page-header.module.css'
import { ManageRentalsDropdown } from './manage-rentals-dropdown/manage-rentals-dropdown'
import { PageHeaderSavedPageLink } from '../../../../features/page-header/page-header-saved-page-link'
import { type PageHeader_QueryFragment } from '../../../../features/page-header/__generated__/page-header.gql'

const manageRentalsOptions = [
  {
    label: 'RentHQ Log In',
    linkDescription: 'Apartment community?',
    href: 'https://renthq.rent.com/login',
    tagItem: 'renthq_log_in',
  },
  {
    label: 'Landlord Log In',
    linkDescription: 'Are you a landlord?',
    href: 'https://list.rent.com/Auth/SignIn',
    tagItem: 'landlord_log_in',
  },
]

type NavLinksProps = {
  marketTrendsUrl?: string | null
  location?: PageHeader_QueryFragment['location']
  mobileContent?: ReactNode
}

export const NavLinks = ({
  marketTrendsUrl,
  location,
  mobileContent,
}: NavLinksProps) => {
  return (
    <>
      <li>
        <PageHeaderSavedPageLink mobileContent={mobileContent} />
      </li>

      {marketTrendsUrl && (
        <li className={clsx(styles.marketTrends, sharedStyles.pageHeaderPipe)}>
          <a
            data-tag_item="market_trends"
            href={marketTrendsUrl}
            className={clsx(sharedStyles.pageHeaderLink)}
          >
            {[location?.city, location?.stateAbbr].filter(Boolean).join(', ')}{' '}
            Market Trends
          </a>
        </li>
      )}

      <li className={clsx(sharedStyles.pageHeaderPipe, styles.listAProperty)}>
        <a
          href="/list"
          data-tag_item="list_a_property"
          className={clsx(sharedStyles.pageHeaderLink)}
        >
          List a Property
        </a>
      </li>

      <li
        className={clsx(sharedStyles.pageHeaderPipe, styles.businessSolutions)}
      >
        <a
          href="https://solutions.rent.com"
          data-tag_item="business_solutions"
          className={clsx(sharedStyles.pageHeaderLink)}
        >
          Business Solutions
        </a>
      </li>

      <ManageRentalsDropdown
        options={manageRentalsOptions}
        className={clsx(sharedStyles.pageHeaderPipe, styles.manageRentals)}
      />

      <li className={clsx(sharedStyles.pageHeaderPipe, styles.movingCenter)}>
        <a
          href="https://www.rent.com/blog/moving-center/"
          data-tag_item="moving_center"
          className={clsx(sharedStyles.pageHeaderLink)}
        >
          Moving Center
        </a>
      </li>
    </>
  )
}
