export const PROPERTY_TYPES_SEPARATOR = '_'
export const REFINEMENT_SEPARATOR = '_'
export const MIN_PRICE_PREFIX = 'min-price-'
export const MAX_PRICE_PREFIX = 'max-price-'
export const BEDROOM_SUFFIX = '-bedroom'
export const BATHROOM_SUFFIX = '-bathroom'
export const STUDIO_SLUG = 'studio'
export const CLEAR_ALL_TEXT = 'Clear All'
export const DEFAULT_SORT_KEY = 'best-match'
export const DEFAULT_SORT_TEXT = 'Best Match'
