export const semLanders: Record<string, string> = {
  '/apartments': `/search?isGenericRewrite=1&propertyTypes=apartments`,
  '/max-price-1000-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-1000`,
  '/max-price-1200-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-1200`,
  '/max-price-500-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-500`,
  '/max-price-600-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-600`,
  '/max-price-800-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-800`,
  '/cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=cheap`,
  '/max-price-1000-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-1000_pet-friendly`,
  '/max-price-1200-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-1200_pet-friendly`,
  '/max-price-500-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-500_pet-friendly`,
  '/max-price-600-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-600_pet-friendly`,
  '/max-price-800-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=max-price-800_pet-friendly`,
  '/pet-friendly-cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=pet-friendly_cheap`,
  '/pet-friendly-luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=pet-friendly_luxury`,
  '/1-bedroom-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom`,
  '/1-bedroom-max-price-1000-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom_max-price-1000`,
  '/1-bedroom-max-price-1200-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom_max-price-1200`,
  '/1-bedroom-max-price-800-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom_max-price-800`,
  '/1-bedroom-cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom_cheap`,
  '/1-bedroom-luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom_luxury`,
  '/1-bedroom-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom_pet-friendly`,
  '/2-bedroom-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom`,
  '/2-bedroom-max-price-1000-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom_max-price-1000`,
  '/2-bedroom-max-price-1200-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom_max-price-1200`,
  '/2-bedroom-max-price-800-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom_max-price-800`,
  '/2-bedroom-cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom_cheap`,
  '/2-bedroom-luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom_luxury`,
  '/2-bedroom-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom_pet-friendly`,
  '/3-bedroom-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom`,
  '/3-bedroom-max-price-1000-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom_max-price-1000`,
  '/3-bedroom-max-price-1200-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom_max-price-1200`,
  '/3-bedroom-max-price-800-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom_max-price-800`,
  '/3-bedroom-cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom_cheap`,
  '/3-bedroom-luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom_luxury`,
  '/3-bedroom-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom_pet-friendly`,
  '/studio-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio`,
  '/studio-max-price-1000-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_max-price-1000`,
  '/studio-max-price-1200-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_max-price-1200`,
  '/studio-max-price-500-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_max-price-500`,
  '/studio-max-price-600-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_max-price-600`,
  '/studio-max-price-800-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_max-price-800`,
  '/studio-cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_cheap`,
  '/studio-luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_luxury`,
  '/studio-pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_pet-friendly`,
  '/studio-pet-friendly-cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio_pet-friendly_cheap`,
  '/condos': `/search?isGenericRewrite=1&propertyTypes=condos`,
  '/max-price-1000-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=max-price-1000`,
  '/max-price-500-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=max-price-500`,
  '/max-price-600-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=max-price-600`,
  '/max-price-800-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=max-price-800`,
  '/cheap-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=cheap`,
  '/pet-friendly-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=pet-friendly`,
  '/pet-friendly-cheap-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=pet-friendly_cheap`,
  '/1-bedroom-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=1-bedroom`,
  '/1-bedroom-pet-friendly-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=1-bedroom_pet-friendly`,
  '/2-bedroom-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=2-bedroom`,
  '/2-bedroom-pet-friendly-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=2-bedroom_pet-friendly`,
  '/3-bedroom-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=3-bedroom`,
  '/3-bedroom-pet-friendly-condos': `/search?isGenericRewrite=1&propertyTypes=condos&refinements=3-bedroom_pet-friendly`,
}
