import { list } from '../../config/shared-links'

const SOLUTIONS_PATH = 'https://solutions.rent.com'
export const DO_NOT_SELL_URL = `${SOLUTIONS_PATH}/do-not-sell/`
export const TERMS_OF_SERVICE_URL = `${SOLUTIONS_PATH}/terms-of-use/`
export const PRIVACY_POLICY_URL = `${SOLUTIONS_PATH}/privacy-policy/`
export const MLS_DISCLOSURES_URL = `${SOLUTIONS_PATH}/mls-disclosures/`
export const ABOUT_US_URL = `${SOLUTIONS_PATH}/about`
export const CONTACT_US_URL = `${SOLUTIONS_PATH}/contact`
export const ANDROID_APP_URL = `https://play.google.com/store/apps/details?id=com.rent&referrer=ts%3D96e725f6-e305-11e4-9781-00163cabc4e4/`
export const IOS_APP_URL = `https://apps.apple.com/us/app/rent-com-apartments-home-rentals/id388038507?mt=8/`
const BLOG = 'https://www.rent.com/blog/'
const RESEARCH = 'https://www.rent.com/research/'
const REDFIN_LINK = 'https://www.redfin.com'

export const FOOTER_LINK_SECTIONS = [
  {
    heading: 'Our Company',
    links: [
      {
        tag: 'about_us',
        text: 'About Us',
        url: ABOUT_US_URL,
      },
      {
        tag: 'media_contact',
        text: 'Press & Media',
        url: CONTACT_US_URL,
      },
      {
        tag: 'redfin',
        text: 'Redfin.com',
        url: REDFIN_LINK,
      },
      {
        tag: 'blog',
        text: 'Rent Blog',
        url: BLOG,
      },
      {
        tag: 'rent_reserach',
        text: 'Rent Research',
        url: RESEARCH,
      },
      {
        tag: 'careers',
        text: 'Careers',
        url: 'https://solutions.rent.com/careers/',
      },
      {
        tag: 'privacy_policy',
        text: 'Privacy Policy',
        url: PRIVACY_POLICY_URL,
      },
      {
        tag: 'terms_of_service',
        text: 'Terms of Service',
        url: TERMS_OF_SERVICE_URL,
      },
      {
        tag: 'california_privacy',
        text: 'Do Not Share or Sell My Personal Information',
        url: DO_NOT_SELL_URL,
      },
    ],
    'data-tid': 'our-company',
    id: 'our-company',
  },
  {
    heading: 'Popular Searches',
    links: [
      {
        tag: 'apartments_for_rent_near_me',
        text: 'Apartments for Rent Near Me',
        url: '/near-me/apartments',
      },
      {
        tag: 'houses_for_rent_near_me',
        text: 'Houses for Rent Near Me',
        url: '/near-me/houses',
      },
      {
        tag: 'one_bedroom_apartments_for_rent_near_me',
        text: '1 Bedroom Apartments Near Me',
        url: '/near-me/1-bedroom-apartments',
      },
      {
        tag: 'studio_apartments_for_rent_near_me',
        text: 'Studio Apartments Near Me',
        url: '/near-me/studio-apartments',
      },
      {
        tag: 'cheap_houses_for_rent_near_me',
        text: 'Cheap Houses Near Me',
        url: '/near-me/cheap-houses',
      },
      {
        tag: 'luxury_apartments_for_rent_near_me',
        text: 'Luxury Apartments Near Me',
        url: '/near-me/luxury-apartments',
      },
      {
        tag: 'pet_friendly_apartments_near_me',
        text: 'Pet Friendly Apartments Near Me',
        url: '/near-me/pet-friendly-apartments',
      },
    ],
    'data-tid': 'popular-searches',
    id: 'popular-searches',
  },
  {
    heading: 'Let Us Help',
    links: [
      {
        tag: 'mobile_app',
        text: 'Download App',
        url: '/mobile-apps',
      },
      {
        tag: 'rental_safety',
        text: 'Avoid Scams',
        url: '/company/security/',
      },
      {
        tag: 'list_a_property',
        text: list.TEXT,
        url: list.URL,
      },
      {
        tag: 'business_solutions',
        text: 'Business Solutions',
        url: 'https://solutions.rent.com',
      },
      {
        tag: 'sitemap',
        text: 'Site Map',
        url: '/sitemap',
      },
      {
        tag: 'accessibility',
        text: 'Accessibility',
        url: 'https://solutions.rent.com/accessibility/',
      },
      {
        tag: 'rent-estimator',
        text: 'Rental Price Estimator',
        url: '/rent-estimator',
        'data-tid': 'rent-estimator-footer-link',
      },
    ],
    'data-tid': 'let-us-help',
    id: 'let-us-help',
  },
]
