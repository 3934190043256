import clsx from 'clsx'
import styles from './logo.module.css'

type LogoProps = {
  className?: string
  colorScheme?: 'dark' // This is ignored on Rent since there is no dark/light logo
  maxWidth?: number
  width?: number
  height?: number
}

export function Logo({ className, maxWidth = 116, width, height }: LogoProps) {
  return (
    <svg
      viewBox="0 0 455 156"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(className, styles.logo)}
      style={{ maxWidth }}
      width={width}
      height={height}
    >
      <path
        d="M409 132.755a22.384 22.384 0 0 0 22.477 22.477c12.488 0 22.755-9.99 22.755-22.477 0-12.488-10.267-22.755-22.755-22.755C418.99 110 409 120.267 409 132.755Z"
        fill="#7E93FB"
      />
      <path
        d="M0 152.907V.814h54.818c11.23 0 20.652 1.951 28.266 5.853 7.661 3.902 13.443 9.375 17.345 16.417 3.949 6.995 5.924 15.156 5.924 24.483 0 9.374-1.998 17.511-5.996 24.411-3.95 6.852-9.778 12.158-17.487 15.917-7.709 3.712-17.178 5.568-28.409 5.568H15.418V71.479h35.474c6.567 0 11.944-.905 16.132-2.713 4.187-1.856 7.28-4.544 9.279-8.066 2.046-3.568 3.07-7.946 3.07-13.133 0-5.187-1.024-9.612-3.07-13.276-2.046-3.712-5.163-6.52-9.35-8.423-4.188-1.95-9.589-2.926-16.203-2.926H26.48v129.965H.001Zm68.12-72.15 38.188 72.15h-29.55l-37.546-72.15H68.12Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M212.26 105.549c.155-2.306.229-4.657.229-7.046 0-31.204-16.226-56.813-52-56.5-30.626.268-52 25.296-52 56.5s21.531 56.706 52.752 56.706c27.76 0 43.71-13.644 50.153-33.696l-21.724-4.329c-3.565 8.142-12.304 16.349-28.429 16.349-14.452 0-28.503-12.228-28.503-27.98h57.51v-.004h22.012Zm-24.906-19.123C186.66 73.786 176.87 63.4 160.241 63.4c-16.628 0-26.297 10.234-27.112 23.026h54.225Z"
        fill="currentColor"
      />
      <path
        d="M393.409 64.288V44.293h-20.111V11h-22.843v14.07c0 6.156-1.048 11.003-4.367 14.234-3.676 3.692-8.547 4.989-13.76 4.989h-3.958v19.995h19.078v59.422c-.048 6.856 1.428 12.569 4.427 17.139 3.047 4.57 7.165 7.95 12.354 10.14 5.19 2.142 11.021 3.118 17.496 2.928 3.957-.103 7.968-.403 11.703-1.791v-21.418c-.712.186-2.653.841-3.991 1.072-1.333.238-2.809.357-4.427.357-2.143 0-4.094-.333-5.856-1-1.761-.666-3.19-1.904-4.285-3.713-1.047-1.857-1.571-4.523-1.571-7.998V64.288h20.111ZM251.518 152.911V88.642c0-5.284.976-9.783 2.928-13.496 1.999-3.714 4.737-6.546 8.212-8.498 3.523-2 7.546-3 12.069-3 6.664 0 11.877 2.071 15.638 6.213 3.809 4.142 5.713 9.879 5.713 17.21v65.84h25.851V83.072c.047-8.76-1.5-16.21-4.642-22.351-3.094-6.142-7.474-10.831-13.139-14.068-5.618-3.237-12.212-4.856-19.781-4.856-18.086 0-32.062 13.777-37.228 30.7h-3.758l6.986-28.272h-24.699v108.686h25.85ZM423 12.794v-1.521h7.168v1.521h-2.672V20h-1.824v-7.206H423ZM431.348 11.273h2.276l2.403 5.863h.102l2.404-5.863h2.275V20h-1.79v-5.68h-.072l-2.258 5.637h-1.219l-2.259-5.659h-.072V20h-1.79v-8.727Z"
        fill="currentColor"
      />
    </svg>
  )
}
