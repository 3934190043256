import clsx from 'clsx'
import { ReactComponent as ChevronDownIcon } from '@brand/icons/chevron-down.svg'
import { ReactComponent as ChevronUpIcon } from '@brand/icons/chevron-up.svg'
import { Menu, Transition } from '@headlessui/react'
import Link from 'next/link'
import { IconButton } from '../../../../../components/icon-button/icon-button'
import { type NavLink } from '../../../../../types'
import styles from './manage-rentals-dropdown.module.css'

export type ManageRentalsDropdownProps = {
  options: NavLink[]
  className: string
}

export function ManageRentalsDropdown({
  options,
  className,
}: ManageRentalsDropdownProps) {
  return (
    <Menu as="li" className={clsx(className, styles.manageRentalsDropdown)}>
      {({ open }) => (
        <>
          <Menu.Button
            as={IconButton}
            icon={
              open ? (
                <ChevronUpIcon
                  aria-hidden
                  className={styles.manageRentalsDropdownIcon}
                />
              ) : (
                <ChevronDownIcon
                  aria-hidden
                  className={styles.manageRentalsDropdownIcon}
                />
              )
            }
            label="Manage Rentals"
            data-tag_item="manage_rentals"
            showLabel
            iconPosition="right"
            data-tid="manage-rentals-dropdown-button"
          />

          <Transition
            enter={styles.manageRentalsDropdownEnter}
            enterFrom={styles.manageRentalsDropdownEnterFrom}
            enterTo={styles.manageRentalsDropdownEnterTo}
            leave={styles.manageRentalsDropdownLeave}
            leaveFrom={styles.manageRentalsDropdownLeaveFrom}
            leaveTo={styles.manageRentalsDropdownLeaveTo}
          >
            <Menu.Items className={styles.manageRentalsDropdownItems}>
              {options?.map((item) => (
                <Menu.Item key={`sub-item-${item.tagItem}`}>
                  {({ active }) => (
                    <Link
                      target="_blank"
                      href={item.href}
                      className={clsx(
                        styles.manageRentalsDropdownItem,
                        active && styles.manageRentalsDropdownItemActive
                      )}
                      data-tag_item={item.tagItem}
                      data-tid={item.tagItem}
                    >
                      {item.linkDescription}

                      <div className={styles.navSubItemLabel}>{item.label}</div>
                    </Link>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
