'use client'

import { ReactComponent as CloseIcon } from '@brand/icons/close.svg'
import { ReactComponent as MenuIcon } from '@brand/icons/menu.svg'
import { hamburgerMenuLinks } from '@brand/config/nav'
import { useWindowKeyPress } from '@rentpath/react-hooks'
import { getAnimationDuration } from '@rentpath/web-utils'
import clsx from 'clsx'
import { useAtom, useAtomValue } from 'jotai'
import Link from 'next/link'
import { useRouter } from 'next/navigation'
import { useCallback, useState } from 'react'
import { BackDrop } from '../../../../../components/back-drop/back-drop'
import { FocusTrap } from '../../../../../components/focus-trap/focus-trap'
import { IconButton } from '../../../../../components/icon-button/icon-button'
import {
  useIsLoggedIn,
  userTotalSavedListings,
  userTotalSavedSearches,
} from '../../../../../features/user/user.store'
import type { DrawerMenu_QueryFragment } from '../../../../../features/page-header/drawer-menu/__generated__/drawer-menu.gql'
import { DrawerMenuSubMenu } from '../../../../../features/page-header/drawer-menu/drawer-menu-sub-menu'
import styles from './drawer-menu.module.css'
import {
  drawerOpen,
  drawerSubMenuOpen,
} from '../../../../../features/page-header/drawer-menu/drawer-menu.store'
import { useOpenModal } from '../../../../../features/modals/use-open-modal'
import { ModalName } from '../../../../../features/modals/modals.config'
import { DrawerMenuUserLinks } from '../../../../../features/page-header/drawer-menu/user-links'

export type DrawerMenuOwnProps = {
  marketTrendsUrl?: string | null
}

export type DrawerMenuProps = DrawerMenuOwnProps & DrawerMenu_QueryFragment

const { manageRentalsLinks, rentalPriceEstimator, researchLink } =
  hamburgerMenuLinks

const links = {
  ['manage rentals']: manageRentalsLinks,
}

export function DrawerMenu(props: DrawerMenuProps) {
  const [isDrawerOpen, setIsDrawerOpen] = useAtom(drawerOpen)
  const [isSubMenuOpen, setIsSubMenuOpen] = useAtom(drawerSubMenuOpen)
  const [selectedSubmenu, setSelectedSubmenu] = useState<string>()
  const [hasDrawerBeenOpened, setHasDrawerBeenOpened] = useState(false)
  const savedPropertiesCount = useAtomValue(userTotalSavedListings)
  const savedSearchesCount = useAtomValue(userTotalSavedSearches)

  const handleClose = useCallback(() => {
    document.documentElement.removeAttribute('style')
    setIsDrawerOpen(false)
    setIsSubMenuOpen(false)
  }, [setIsDrawerOpen, setIsSubMenuOpen])

  useWindowKeyPress('Escape', handleClose)

  const handleOpen = useCallback(() => {
    document.documentElement.style.overflow = 'hidden'
    document.documentElement.style.width = '100%'
    setIsDrawerOpen(true)
    setHasDrawerBeenOpened(true)
  }, [setIsDrawerOpen, setHasDrawerBeenOpened])

  const handleOpenSubMenu = useCallback(
    (selectedSubmenuKey: string) => {
      setIsSubMenuOpen(true)
      setSelectedSubmenu(selectedSubmenuKey)
    },
    [setIsSubMenuOpen, setSelectedSubmenu]
  )

  const handleCloseSubMenu = () => setIsSubMenuOpen(false)

  return (
    <div data-tag_section="menu">
      <button
        aria-expanded={isDrawerOpen}
        data-tid="drawer-menu-open-btn"
        aria-label="Open main navigation menu"
        className={styles.drawerMenuTrigger}
        onClick={handleOpen}
        type="button"
        data-tag_item="hamburger"
      >
        <MenuIcon aria-hidden className={styles.drawerMenuIcon} />
      </button>

      <DrawerMenuContent
        selectedSubmenu={selectedSubmenu}
        handleOpenSubMenu={handleOpenSubMenu}
        handleCloseSubMenu={handleCloseSubMenu}
        location={props.location}
        isDrawerOpen={isDrawerOpen}
        isSubMenuOpen={isSubMenuOpen}
        savedPropertiesCount={savedPropertiesCount}
        savedSearchesCount={savedSearchesCount}
        marketTrendsUrl={props.marketTrendsUrl}
        hasDrawerBeenOpened={hasDrawerBeenOpened}
        handleClose={handleClose}
      />
    </div>
  )
}

function DrawerMenuContent(props: {
  selectedSubmenu: string | undefined
  handleOpenSubMenu: (selectedSubmenuKey: string) => void
  handleCloseSubMenu: () => void
  location: DrawerMenuProps['location']
  isDrawerOpen: boolean
  isSubMenuOpen: boolean
  savedPropertiesCount: number
  savedSearchesCount: number
  marketTrendsUrl?: string | null
  hasDrawerBeenOpened: boolean
  handleClose: () => void
}) {
  const router = useRouter()
  const [animationDuration, setAnimationDuration] = useState(0)
  const isLoggedIn = useIsLoggedIn()
  const openModal = useOpenModal()

  return (
    <>
      <BackDrop onClick={props.handleClose} isVisible={props.isDrawerOpen} />

      <nav
        ref={(node) => {
          if (node) {
            setAnimationDuration(getAnimationDuration(node))
          }
        }}
        aria-label="Main navigation menu"
        aria-hidden={!props.isDrawerOpen}
        className={clsx(
          styles.drawerMenuPanel,
          props.isDrawerOpen && styles.drawerMenuPanelOpen,
          props.hasDrawerBeenOpened &&
            !props.isDrawerOpen &&
            styles.drawerMenuPanelClose
        )}
        data-tid="drawer-menu"
      >
        <FocusTrap
          enabled={props.isDrawerOpen && !props.isSubMenuOpen}
          timeout={animationDuration}
          className={clsx(
            styles.drawerMenuSubMenuBase,
            props.isSubMenuOpen
              ? styles.drawerMenuShowSubMenu
              : styles.drawerMenuBackToMainMenu
          )}
        >
          <header className={styles.drawerMenuHeader}>
            <IconButton
              data-tid="drawer-menu-close-btn"
              icon={<CloseIcon aria-hidden="true" />}
              label={
                <>
                  Close<span className="sr-only"> navigation menu</span>
                </>
              }
              variant="anchor"
              showLabel
              iconPosition="right"
              onClick={props.handleClose}
              type="button"
            />
          </header>

          <div className={styles.drawerMenuBody}>
            <ul className={styles.drawerMenuItems}>
              <li>
                <a
                  href="/"
                  data-tag_item="home"
                  className={styles.drawerMenuItem}
                >
                  Home
                </a>
              </li>

              <li>
                <a
                  href="/"
                  data-tag_item="search"
                  className={styles.drawerMenuItem}
                >
                  Search
                </a>
              </li>

              <li>
                <Link
                  prefetch={false}
                  href="/saved"
                  data-tag_item="my_rent"
                  data-tid="saved-properties-menu"
                  className={styles.drawerMenuItem}
                  onClick={() => {
                    props.handleClose()
                  }}
                >
                  <span>
                    Saved Properties{' '}
                    {props.savedPropertiesCount > 0 && (
                      <span data-tid="count" className={styles.drawerMenuCount}>
                        {props.savedPropertiesCount}
                      </span>
                    )}
                  </span>
                </Link>
              </li>

              <li>
                <Link
                  prefetch={false}
                  href="/saved-searches"
                  className={styles.drawerMenuItem}
                  onClick={(e) => {
                    if (!isLoggedIn) {
                      e.preventDefault()
                      openModal({
                        id: ModalName.SIGN_IN,
                        props: {
                          onSuccess: () => router.push('/saved-searches'),
                          isRenterOnlyLogin: true,
                        },
                      })
                    }

                    props.handleClose()
                  }}
                  data-tid="saved-searches"
                  data-tag_item="saved_searches"
                >
                  <span>
                    Saved Searches{' '}
                    {isLoggedIn && (
                      <span
                        data-tid="saved-search-count"
                        className={styles.drawerMenuCount}
                      >
                        {props.savedSearchesCount}
                      </span>
                    )}
                  </span>
                </Link>
              </li>

              {props.marketTrendsUrl && (
                <li>
                  <a
                    data-tag_item="market_trends"
                    href={props.marketTrendsUrl}
                    className={styles.drawerMenuItem}
                  >
                    {[props.location?.city, props.location?.stateAbbr]
                      .filter(Boolean)
                      .join(', ')}{' '}
                    Market Trends
                  </a>
                </li>
              )}
              <li className={styles.movingCenter}>
                <a
                  href="/blog/moving-center/"
                  data-tag_item="moving_center"
                  target="_blank"
                  className={styles.drawerMenuItem}
                >
                  Moving Center
                </a>
              </li>

              <li>
                <a
                  href="/list"
                  data-tag_item="list_a_property"
                  target="_blank"
                  className={styles.drawerMenuItem}
                >
                  List a Property
                </a>
              </li>

              {manageRentalsLinks && (
                <DrawerMenuSubMenu
                  links={links}
                  selectedSubmenu={props.selectedSubmenu}
                  handleOpenSubMenu={props.handleOpenSubMenu}
                  isSubMenuOpen={props.isSubMenuOpen}
                  onSubMenuClose={props.handleCloseSubMenu}
                />
              )}

              <li>
                <a
                  href="https://solutions.rent.com"
                  data-tag_item="business_solutions"
                  className={styles.drawerMenuItem}
                  target="_blank"
                  rel="noreferrer"
                >
                  Business Solutions
                </a>
              </li>

              <li>
                <a
                  href="/blog/rent-calculator"
                  data-tag_item="rent-calculator"
                  target="_blank"
                  className={styles.drawerMenuItem}
                >
                  Rent Calculator
                </a>
              </li>

              {rentalPriceEstimator && (
                <li>
                  <a
                    href={rentalPriceEstimator.url}
                    data-tag_item={rentalPriceEstimator.dataTagItem}
                    target={rentalPriceEstimator.target}
                    className={styles.drawerMenuItem}
                  >
                    {rentalPriceEstimator.displayName}
                  </a>
                </li>
              )}

              <li>
                <a
                  href="/blog/"
                  data-tag_item="blog"
                  target="_blank"
                  className={styles.drawerMenuItem}
                >
                  Blog
                </a>
              </li>

              {researchLink && (
                <li>
                  <a
                    href={researchLink.url}
                    data-tag_item={researchLink.dataTagItem}
                    target={researchLink.target}
                    className={styles.drawerMenuItem}
                  >
                    {researchLink.displayName}
                  </a>
                </li>
              )}

              <li className={styles.drawerMenuDivider}>
                <a
                  href="/mobile-apps"
                  data-tag_item="download_apps"
                  className={styles.drawerMenuItem}
                >
                  Download the App
                </a>
              </li>

              <DrawerMenuUserLinks
                handleClose={props.handleClose}
                animationDuration={animationDuration}
              />
            </ul>
          </div>
        </FocusTrap>
      </nav>
    </>
  )
}
