export const nearMe: Record<string, string> = {
  '/near-me/apartments': '/search?isGenericRewrite=1&propertyTypes=apartments',
  '/near-me/furnished-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=furnished`,
  '/near-me/senior-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=senior-living`,
  '/near-me/loft-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=loft`,
  '/near-me/income-based-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=income-restricted`,
  '/near-me/new-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=new`,
  '/near-me/apartment-complexes': `/search?isGenericRewrite=1&propertyTypes=apartments`,
  '/near-me/cheap-houses': `/search?isGenericRewrite=1&propertyTypes=houses&refinements=cheap`,
  '/near-me/houses': '/search?isGenericRewrite=1&propertyTypes=houses',
  '/near-me/townhomes': '/search?isGenericRewrite=1&propertyTypes=townhouses',
  '/near-me/cheap-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=cheap`,
  '/near-me/luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=luxury`,
  '/near-me/pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=pet-friendly`,
  '/near-me/1-bedroom-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom`,
  '/near-me/2-bedroom-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom`,
  '/near-me/3-bedroom-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom`,
  '/near-me/studio-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio`,
  '/near-me/condos': `/search?isGenericRewrite=1&propertyTypes=condos`,
}

export const showNearMeInResultsSort = true
