export const seoLanders: Record<string, string> = {
  // these now redirect to /near-me/ pages
  '/pet-friendly-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=pet-friendly`,
  '/houses-for-rent': `/search?isGenericRewrite=1&propertyTypes=houses`,
  '/cheap-apartments-for-rent': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=cheap`,
  '/townhomes-for-rent': `/search?isGenericRewrite=1&propertyTypes=townhouses`,
  '/studio-apartment': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=studio`,
  '/condos-for-rent': `/search?isGenericRewrite=1&propertyTypes=condos`,
  '/1-bedroom-apartment': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=1-bedroom`,
  '/2-bedroom-apartment': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=2-bedroom`,
  '/3-bedroom-apartment': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=3-bedroom`,
  '/luxury-apartments': `/search?isGenericRewrite=1&propertyTypes=apartments&refinements=luxury`,
}
