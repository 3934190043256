const singlePropertyType = ':propertyTypes(apartments|condos|houses|townhouses)'
const multiPropertyType =
  ':propertyTypes((?:apartments|condos|houses|townhouses){1}(?:_apartments|_condos|_houses|_townhouses){1,3})'

export const poiSearchPaths = [
  // Matches => /p/georgia/atlanta-houses/grady-memorial-hospital
  // Matches => /p/georgia/atlanta-houses/grady-memorial-hospital/2-bedroom
  `/p/:state/:location-${singlePropertyType}/:poi/:refinements?`,
  // Matches => /p/texas/arlington/six-flags-over-texas-apartments
  // Matches => /p/texas/arlington/six-flags-over-texas-apartments/2-bedroom
  `/p/:state/:location/:poi-${singlePropertyType}/:refinements?`,
  // Matches => /p/texas/arlington/six-flags-over-texas/apartments_condos_houses_townhouses
  // Matches => /p/texas/arlington/six-flags-over-texas/apartments_condos_houses_townhouses_2-bedroom
  `/p/:state/:location/:poi/${multiPropertyType}{_:refinements}?`,
]

export const schoolDistrictSearchPaths = [
  // Matches => /oregon/schools/seaside-school-district-10-4111100-apartments
  // Matches => /oregon/schools/seaside-school-district-10-4111100-apartments/2-bedroom
  `/:state/schools/:location-:schoolDistrict-${singlePropertyType}/:refinements?`,
  // Matches => /oregon/schools/seaside-school-district-10-4111100-apartments
  // Matches => /oregon/schools/seaside-school-district-10-4111100-apartments/2-bedroom
  `/:state/schools/:schoolDistrict-${singlePropertyType}/:refinements?`,
  // Matches => /oregon/schools/seaside-school-district-10-4111100/apartments_houses
  // Matches => /oregon/schools/seaside-school-district-10-4111100/apartments_houses_2-bedroom
  `/:state/schools/:location-:schoolDistrict/${multiPropertyType}{_:refinements}?`,
  // Matches => /oregon/schools/seaside-school-district-10-4111100/apartments_houses
  // Matches => /oregon/schools/seaside-school-district-10-4111100/apartments_houses_2-bedroom
  `/:state/schools/:schoolDistrict/${multiPropertyType}{_:refinements}?`,
]

export const schoolSearchPaths = [
  // Matches => /oregon/seaside/schools/seaside-high-school-411110000163-apartments
  // Matches => /oregon/seaside/schools/seaside-high-school-411110000163-apartments/2-bedroom
  `/:state/:location/schools/:school-${singlePropertyType}/:refinements?`,
  // Matches => /oregon/seaside/schools/seaside-high-school-411110000163/apartments_houses
  // Matches => /oregon/seaside/schools/seaside-high-school-411110000163/apartments_houses_2-bedroom
  `/:state/:location/schools/:school/${multiPropertyType}{_:refinements}?`,
]

export const hoodSearchPaths = [
  // Matches => /georgia/atlanta-apartments/buckhead-neighborhood
  // Matches => /georgia/atlanta-apartments/buckhead-neighborhood/2-bedroom
  `/:state/:location-${singlePropertyType}/:hood-neighborhood/:refinements?`,
  // Matches => /georgia/atlanta/buckhead-neighborhood/apartments_houses
  // Matches => /georgia/atlanta/buckhead-neighborhood/apartments_houses_2-bedroom
  `/:state/:location/:hood-neighborhood/${multiPropertyType}{_:refinements}?`,
]

export const citySearchPaths = [
  // Matches => /georgia/atlanta-apartments
  // Matches => /georgia/atlanta-apartments/studio_2-bedroom
  `/:state/:location-${singlePropertyType}/:refinements?`,
  // Matches => /georgia/atlanta/apartments_condos_houses_townhouses
  // Matches => /georgia/atlanta/apartments_condos_houses_townhouses_2-bedroom
  `/:state/:location/${multiPropertyType}{_:refinements}?`,
  `/:state/:location-rooms-for-rent`,
]

export const zipSearchPaths = [
  // Matches => /zip-30066-apartments
  // Matches => /zip-30066-apartments/2-bedroom
  `/zip-:zipCode-${singlePropertyType}/:refinements?`,
  // Matches => /zip-30066/apartments_houses
  // Matches => /zip-30066/apartments_houses_2-bedroom
  `/zip-:zipCode/${multiPropertyType}{_:refinements}?`,
]

export const countySearchPaths = [
  // Matches => /georgia/counties/fulton-apartments
  // Matches => /georgia/counties/fulton-apartments/2-bedroom
  `/:state/counties/:county-${singlePropertyType}/:refinements?`,
  // Matches => /georgia/counties/fulton/apartments_houses
  // Matches => /georgia/counties/fulton/apartments_houses_2-bedroom
  `/:state/counties/:county/${multiPropertyType}{_:refinements}?`,
]
