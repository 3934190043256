import type { HamburgerMenuLinks } from '../../../types'

export const hamburgerMenuLinks: HamburgerMenuLinks = {
  manageRentalsLinks: [
    {
      dataTagItem: 'rent_hq_log_in',
      displayName: 'RentHQ Log In',
      url: 'https://renthq.rent.com/login',
      target: '_blank',
      ref: 'noreferrer',
      linkDescription: 'Apartment community?',
    },
    {
      dataTagItem: 'landlord_log_in',
      displayName: 'Landlord Log In',
      url: 'https://list.rent.com/Auth/SignIn',
      target: '_blank',
      ref: 'noreferrer',
      linkDescription: 'Are you a landlord?',
    },
  ],
  rentalPriceEstimator: {
    dataTagItem: 'rent_estimator',
    displayName: 'Rental Price Estimator',
    url: '/rent-estimator',
    target: '_blank',
  },
  researchLink: {
    dataTagItem: 'rent_research',
    displayName: 'Rent Research',
    url: '/research/',
    target: '_blank',
  },
}
